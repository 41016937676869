<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="registry_data col-md-3">
                      <p>Contraente attuale:</p>
                      <span
                        v-if="registry_data_initial"
                        class="info"
                        v-html="toInfoData(registry_data_initial, 'registry')"
                      >
                      </span>
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].number.label"
                        vid="number"
                        :label="beForm[rep].number.label"
                        v-model="form[rep].number"
                        placeholder="Inserisci un numero polizza"
                        :rules="getRules('number')"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="insurer_id"
                        :name="beForm[rep].insurer_id.label"
                        :label="beForm[rep].insurer_id.label"
                        v-model="form[rep].insurer_id"
                        :options="companies"
                        @input="onInputInsurer"
                        :rules="getRules('insurer_id')"
                        :disabled="true"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Nuovo contraente"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <p>Scegli qui il nuovo contraente</p>
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              :name="beForm[rep].registry_id.label"
                              :label="beForm[rep].registry_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('registry_id')"
                              :readonly="false"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              :disabled="false"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
// import CustomInputs from "@/components/form/CustomInputs";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
// import BaseDatepicker from "@/components/form/BaseDatepicker";
// import BaseCurrency from "@/components/form/BaseCurrency";
// import { calcPolicyDate } from "@/utils/dates";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
import { today } from "@/utils/dates";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin, ConfirmModalMixin],
  data() {
    return {
      repository: "insurance_policy",
      isLoadingAjax: false,
      isImported: false,
      // isImportedStatusPolicy: false,
      disabled: false,
      ajaxOptions: [],
      // broker_options: [],
      // mandate_code_options: [],
      companies: [],
      // risk_branches: [],
      // risks: [],
      // products: [],
      id: this.$route.params.id,
      // item: this.$route.params.item,
      registry_data: null,
      registry_data_initial: null, // remember initial registry (before any user's change)
      // suspendedAt: true,
      // cancellaedAt: true,
      form: {
        quick_value: "",
        inpt_label: "",
        insurance_policy: {
          number: null,
          registry_id: null,
          insurer_id: null,
        },
        insurance_ancillary: {
          code: "A",
          title: null,
          effective_at: null,
          delay_days: null,
          insurance_policy_id: null,
          broker_id: null,
          status_ancillary: null,
          net: 0.0,
          gross: 0.0,
          tax: 0.0,
        },
      },
    };
  },
  props: {},
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    QuickSearchRegistryModal,
  },
  methods: {
    toInfoData,
    onInputInsurer(insurer) {
      this.form[this.rep].insurer_id = insurer;
    },
    openQuickSearchRegistry() {
      this.form[this.rep].registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.form[this.rep].registry_id = value.id;
      this.registry_data = value;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    onSubmit() {
      this.showConfirm({
        yesCallback: () => {
          this.doTrasfer();
        },
        noCallback: null,
        title: "Conferma operazione",
        message: `ATTENZIONE: se esistono titoli in stato "da incassare" diventeranno del nuovo contraente`,
        yesLabel: "PROCEDI",
        noLabel: "ANNULLA",
      });
    },
    doTrasfer() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          /*
          // aggiungere un titolo
          tipo A
          incassato,
          valore 0,
          descrizione "Voltura da contrante NOME COGNOME VECCHIO a NOME COGNOME NUOVO"
          */
          this.form[
            "insurance_ancillary"
          ].title = `Voltura da contraente ${toInfoData(
            this.registry_data_initial,
            "registryfullname"
          )} a ${toInfoData(this.registry_data, "registryfullname")}`;
          this.form["insurance_ancillary"].effective_at = today();
          this.form["insurance_ancillary"].insurance_policy_id = this.id;
          this.form["insurance_ancillary"].broker_id =
            this.form[this.rep].broker_id;
          this.form["insurance_ancillary"].status_ancillary = 1;
          const Repo = RepositoryFactory.get("insurance_ancillary");
          Repo.store(this.form["insurance_ancillary"])
            .then(() => {
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Voltura effettuata`,
              });
              this.shortcut(
                "module.PFOLIO",
                null,
                "#Policies",
                "filterInsurancePolicy"
              );
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    addAncillary() {},
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
    }),
  },
  created() {
    this.isLoading = true;
    this.broker_options = this.getSalesmen();
    this.fetchEditForm(this.repository, this.id).then(() => {
      this.companies = this.getInsurers();
      const Repo = RepositoryFactory.get("registry");
      Repo.show(this.beForm[this.rep].registry_id.value)
        .then((response) => {
          const data = response.data.data;
          this.registry_data_initial = data;
          this.form[this.rep].registry_id =
            this.beForm[this.rep].registry_id.value;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
  },
  watch: {
    "form.inpt_label": {
      deep: true,
      handler() {
        if (!this.form[this.rep].registry_id) {
          this.registry_data = null;
        }
      },
    },
  },
};
</script>
