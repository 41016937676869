<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              :name="beForm[rep].registry_id.label"
                              :label="beForm[rep].registry_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('registry_id')"
                              :readonly="true"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="insurer_id"
                        :name="beForm[rep].insurer_id.label"
                        :label="beForm[rep].insurer_id.label"
                        v-model="form[rep].insurer_id"
                        :options="companies"
                        @input="onInputInsurer"
                        :rules="getRules('insurer_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="number"
                        :name="beForm[rep].number.label"
                        :label="beForm[rep].number.label"
                        v-model="form[rep].number"
                        placeholder="Inserisci un numero polizza"
                        :rules="getRules('number')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="title"
                        :name="beForm[rep].title.label"
                        label="Descrizione libera"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="mandate_code_id"
                        :name="beForm[rep].mandate_code_id.label"
                        :label="beForm[rep].mandate_code_id.label"
                        :options="mandate_code_options"
                        v-model="form[rep].mandate_code_id"
                        placeholder="Seleziona un codice mandato"
                        :rules="{ required: true }"
                        :disabled="!form[rep].insurer_id"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Prodotto Assicurativo"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="col-md-3">
                      <base-select
                        name="Ramo"
                        vid="branch"
                        label="Ramo"
                        :options="risk_branches"
                        v-model="form.risk_branch_id"
                        :multiple="false"
                        @input="onInputBranch"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="insurance_risk_id"
                        :name="beForm[rep].insurance_risk_id.label"
                        :label="beForm[rep].insurance_risk_id.label"
                        v-model="form[rep].insurance_risk_id"
                        :options="prdct"
                        @select="onSelectRisk"
                        @remove="onRemoveRisk"
                        :rules="getRules('insurance_risk_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="delay_days"
                        :name="beForm[rep].delay_days.label"
                        :label="beForm[rep].delay_days.label"
                        v-model="form[rep].delay_days"
                        :options="beForm[rep].delay_days.options"
                        :rules="getRules('delay_days')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Date" header-tag="header" class="mt-1">
                <b-card-text>
                  <b-row>
                    <div class="col-md-3">
                      <base-select
                        :name="beForm[rep].broker_id.label"
                        vid="broker_id"
                        :label="beForm[rep].broker_id.label"
                        :options="broker_options"
                        v-model="form[rep].broker_id"
                        :rules="getRules('broker_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="payment_splitting"
                        :name="beForm[rep].payment_splitting.label"
                        :label="beForm[rep].payment_splitting.label"
                        v-model="form[rep].payment_splitting"
                        :options="beForm[rep].payment_splitting.options"
                        :rules="getRules('payment_splitting')"
                        @select="onPaymentSplitting"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="status_policy"
                        :name="beForm[rep].status_policy.label"
                        :label="beForm[rep].status_policy.label"
                        v-model="form[rep].status_policy"
                        :options="status_policy_opt"
                        :rules="getRules('status_policy')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="issued_at"
                        :name="beForm[rep].issued_at.label"
                        :label="beForm[rep].issued_at.label"
                        v-model="form[rep].issued_at"
                        @select="
                          onPaymentSplitting(form[rep].payment_splitting)
                        "
                        :rules="getRules('issued_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="effective_at"
                        :name="beForm[rep].effective_at.label"
                        :label="beForm[rep].effective_at.label"
                        v-model="form[rep].effective_at"
                        @select="
                          onPaymentSplitting(form[rep].payment_splitting, true)
                        "
                        :rules="getRules('effective_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        ref="expired_at"
                        vid="expired_at"
                        :name="beForm[rep].expired_at.label"
                        :label="beForm[rep].expired_at.label"
                        v-model="form[rep].expired_at"
                        :rules="getRules('expired_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="covered_at"
                        :name="beForm[rep].covered_at.label"
                        :label="beForm[rep].covered_at.label"
                        v-model="form[rep].covered_at"
                        :rules="getRules('covered_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="suspended_at"
                        :name="beForm[rep].suspended_at.label"
                        :label="beForm[rep].suspended_at.label"
                        v-model="form[rep].suspended_at"
                        :rules="getRules('suspended_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="cancelled_at"
                        :name="beForm[rep].cancelled_at.label"
                        :label="beForm[rep].cancelled_at.label"
                        v-model="form[rep].cancelled_at"
                        :rules="getRules('cancelled_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_NONOT_value"
                        :name="beForm[rep].attribute_NONOT_value.label"
                        label="Escludere da Avvisi di Scadenza"
                        v-model="form[rep].attribute_NONOT_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_NONOT_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Premio" header-tag="header" class="mt-1">
                <div @click="onClickBreakdownsSwitch()" class="switch">
                  <b-form-checkbox
                    v-model="isBreakdown"
                    name="check-button"
                    switch
                    :disabled="
                      !form[rep].insurance_risk_id ||
                      breakdowns.length == 0 ||
                      isLoadingBreakdown
                    "
                    @change="onChangeBreakdownsSwitch()"
                  >
                    {{ isBreakdown ? "Scorpori Premio" : "Premio" }}
                  </b-form-checkbox>
                </div>
                <b-card-text v-if="!isBreakdown">
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].net_premium.label"
                        vid="net_premium"
                        :label="beForm[rep].net_premium.label"
                        v-model="form[rep].net_premium"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="getRules('net_premium')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        name="Tasse"
                        vid="tax_premium"
                        label="Tasse"
                        v-model="form[rep].tax_premium"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="getRules('tax_premium')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].gross_premium.label"
                        vid="gross_premium"
                        :label="beForm[rep].gross_premium.label"
                        v-model="grossPremiumSum"
                        :readonly="true"
                        custom_type="currency"
                        :rules="getRules('gross_premium')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
                <b-card-text v-if="isBreakdown">
                  <div v-if="breakdowns.length">
                    <b-overlay center :show="isLoadingBreakdown" rounded="sm">
                      <b-table
                        hover
                        :items="breakdowns"
                        :fields="fields"
                        ref="breakdowns"
                      >
                        <template v-slot:cell(net)="row">
                          <div class="form-group col-md-7">
                            <base-currency
                              :name="beForm[rep].net_premium.label"
                              :vid="`net-${row.item.id}`"
                              :label="beForm[rep].net_premium.label"
                              v-model="row.item.net.value"
                              :options="{
                                currency: 'EUR',
                                locale: 'it-IT',
                                precision: 2,
                              }"
                              :readonly="
                                row.item.net.is_net === 'Y' ? false : true
                              "
                            />
                          </div>
                        </template>
                        <template v-slot:cell(tax)="row">
                          <div class="form-group col-md-7">
                            <base-currency
                              name="Tasse"
                              :vid="`tax-${row.item.id}`"
                              label="Tasse"
                              v-model="row.item.tax.value"
                              :options="{
                                currency: 'EUR',
                                locale: 'it-IT',
                                precision: 2,
                              }"
                              :readonly="
                                row.item.tax.is_tax === 'Y' ? false : true
                              "
                            />
                          </div>
                        </template>
                        <template v-slot:cell(grossrow)="row">
                          <div class="form-group col-md-7">
                            <base-input
                              name="grossrow"
                              :vid="`grossrow-${row.item.id}`"
                              label="Lordo Riga"
                              custom_type="currency"
                              :value="
                                grossRowBookEntry(
                                  row.item.tax.value,
                                  row.item.net.value
                                )
                              "
                              :readonly="true"
                            />
                          </div>
                        </template>
                        <template slot="bottom-row">
                          <td>Totale</td>
                          <td>
                            <div class="form-group col-md-7">
                              <base-label
                                class="net-sum-label"
                                :value="netSum"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-md-7">
                              <base-label
                                class="tax-sum-label"
                                :value="taxSum"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-md-7">
                              <base-label
                                class="gross-sum-label"
                                :value="grossSum"
                              />
                            </div>
                          </td>
                        </template>
                      </b-table>
                      <template #overlay>
                        <div class="text-center">
                          <base-icon name="loading" width="35" height="35" />
                          <p id="cancel-label">Operazione in corso...</p>
                        </div>
                      </template>
                    </b-overlay>
                  </div>
                </b-card-text>
              </b-card>
              <b-card header="Collaborazioni" header-tag="header" class="mt-1">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="collaborator_id"
                        :name="beForm[rep].collaborator_id.label"
                        :label="beForm[rep].collaborator_id.label"
                        v-model="form[rep].collaborator_id"
                        :options="beForm[rep].collaborator_id.options"
                        @input="onInputCooperator"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        v-if="form[rep].collaborator_id"
                        vid="tipo_incasso"
                        :name="beForm[rep].collaboration_type.label"
                        label="Tipo Incasso"
                        v-model="form[rep].collaboration_type"
                        :options="[
                          { text: 'Incasso diretto', value: 0 },
                          { text: 'Tramite collaborazione', value: 1 },
                          { text: 'Nessuna ', value: 2 },
                        ]"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Coassicurazioni" header-tag="header" class="mt-1">
                <b-card-text>
                  <div class="switch">
                    <b-form-checkbox
                      v-model="isInsurerParticipant"
                      name="check-button"
                      switch
                      :disabled="isBreakdown"
                    >
                      Polizza in coass
                    </b-form-checkbox>
                  </div>
                  <b-row v-if="isInsurerParticipant">
                    <div class="form-group col-md-4">
                      <!-- Tipologia delega coassicurazione -->
                      <base-select
                        ref="sharing_type"
                        vid="sharing_type"
                        :name="beForm[rep].sharing_type.label"
                        :label="beForm[rep].sharing_type.label"
                        v-model="form[rep].sharing_type"
                        :rules="getRules('sharing_type')"
                        :options="beForm[rep].sharing_type.options"
                        @input="onInputInsurerParticipantOptions"
                      />
                      <!-- @select="onSelectInsurerParticipantOptions"
                        @remove="onRemoveInsurerParticipantOptions" -->
                    </div>
                  </b-row>
                  <b-card
                    header="Delega - Nostra"
                    header-tag="header"
                    class="mt-1"
                    v-if="form[rep].sharing_type === 0 && isInsurerParticipant"
                  >
                    <b-card-text>
                      <!-- row header -->
                      <b-row>
                        <div class="col-md-2"></div>
                        <div class="col-md-2">
                          <!-- Quota Nostra -->
                          <base-currency
                            vid="quota"
                            name="quota"
                            label="Quota Nostra"
                            v-model="form[rep].sharing"
                            :options="{
                              locale: 'it-IT',
                              currencyDisplay: 'percent',
                              precision: 2,
                            }"
                            placeholder="... quota"
                          />
                          <!-- v-model="form.coass.share" -->
                        </div>
                        <div class="col-md-2">
                          <base-currency
                            vid="net"
                            name="net"
                            label="Netto"
                            :value="form[rep].net_premium"
                            :options="{ currency: 'EUR', locale: 'it-IT' }"
                            :readonly="true"
                          />
                        </div>
                        <div class="col-md-2">
                          <base-currency
                            vid="tax"
                            name="tax"
                            label="Tasse"
                            :value="form[rep].tax_premium"
                            :options="{ currency: 'EUR', locale: 'it-IT' }"
                            :readonly="true"
                          />
                        </div>
                        <div class="col-md-2">
                          <base-currency
                            vid="gross"
                            name="gross"
                            label="Lordo"
                            :value="grossPremiumSum"
                            :options="{ currency: 'EUR', locale: 'it-IT' }"
                            :readonly="true"
                          />
                        </div>
                      </b-row>
                      <hr />
                      <coass-details
                        ref="coassRef"
                        :details="coassdtls"
                        v-model="form"
                        repository="insurer_participant"
                        canDelete
                        @select="
                          selectCreateCoassDetail(
                            form.coass.coass_details,
                            ...arguments
                          )
                        "
                        @unselect="
                          unselectCreateCoassDetail(
                            form.coass.coass_details,
                            ...arguments
                          )
                        "
                        @delete="
                          deleteCreateCoassDetail(
                            form.coass.coass_details,
                            ...arguments
                          )
                        "
                      ></coass-details>
                      <hr />
                      <!-- row footer -->
                      <b-row>
                        <div class="col-md-2"></div>
                        <div class="col-md-2">
                          <!-- Totale Quota Nostra + Coass -->
                          <!-- :value="
                              form.coass.share +
                              coassDetailsCreateTotal('share')
                            " -->
                          <base-currency
                            vid="quota"
                            name="quota"
                            label="Totale Quota"
                            :value="
                              parseFloat(form[rep].sharing) +
                              coassDetailsCreateTotal('share')
                            "
                            :options="{
                              locale: 'it-IT',
                              currencyDisplay: 'percent',
                              precision: 2,
                            }"
                            :readonly="true"
                          />
                        </div>
                        <div class="col-md-2">
                          <base-currency
                            vid="net"
                            name="net"
                            label="Totale Netto"
                            :value="
                              parseFloat(form[rep].net_premium) +
                              coassDetailsCreateTotal('net')
                            "
                            :options="{ currency: 'EUR', locale: 'it-IT' }"
                            :readonly="true"
                          />
                        </div>
                        <div class="col-md-2">
                          <base-currency
                            vid="tax"
                            name="tax"
                            label="Totale Tasse"
                            :value="
                              parseFloat(form[rep].tax_premium) +
                              coassDetailsCreateTotal('tax')
                            "
                            :options="{ currency: 'EUR', locale: 'it-IT' }"
                            :readonly="true"
                          />
                        </div>
                        <div class="col-md-2">
                          <base-currency
                            vid="gross"
                            name="gross"
                            label="Totale Lordo"
                            :value="
                              parseFloat(form[rep].net_premium) +
                              parseFloat(form[rep].tax_premium) +
                              coassDetailsCreateTotal('net') +
                              coassDetailsCreateTotal('tax')
                            "
                            :options="{ currency: 'EUR', locale: 'it-IT' }"
                            :readonly="true"
                          />
                        </div>
                      </b-row>
                    </b-card-text>
                  </b-card>
                  <b-card
                    header="Delega - Altrui"
                    header-tag="header"
                    class="mt-1"
                    v-if="form[rep].sharing_type === 1 && isInsurerParticipant"
                  >
                    <b-card-text>
                      <b-row>
                        <div class="col-md-4">
                          <base-select
                            vid="insurer_participant"
                            name="insurer_participant"
                            label="Compagnia Coass"
                            v-model="form.insurer_participant"
                            :options="getCoassOptions()"
                          />
                        </div>
                        <div class="col-md-4">
                          <base-currency
                            vid="sharing"
                            :name="beForm[rep].sharing.label"
                            :label="beForm[rep].sharing.label"
                            v-model="form[rep].sharing"
                            :rules="getRules('sharing')"
                            :options="{
                              locale: 'it-IT',
                              currencyDisplay: 'percent',
                              precision: 2,
                            }"
                            placeholder="Inserisci Percentuale"
                          />
                        </div>
                      </b-row>
                    </b-card-text>
                  </b-card>
                </b-card-text>
              </b-card>
              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <b-row>
                      <custom-inputs
                        v-for="(element, index) in customInputs[rep]"
                        :key="index"
                        :input="element"
                        v-model="form[rep]"
                        :beForm="beForm[rep]"
                        :beRules="beRules[rep]"
                      />
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseLabel from "@/components/form/BaseLabel";
import CustomInputs from "@/components/form/CustomInputs";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import CoassDetailsMixin from "@/mixins/CoassDetailsMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CoassDetails from "@/components/form/CoassDetails";
import { toLocaleCurrency } from "@/utils/strings";
import { toInfoData } from "@/utils/transforms";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import FormMixin from "@/mixins/FormMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { calcPolicyDate, today } from "@/utils/dates";
import { mapGetters } from "vuex";
import { sumBreakdowns, dataMappingPremium } from "@/utils/breakdowns";

export default {
  mixins: [
    FormMixin,
    ShortcutMixin,
    ConfirmModalMixin,
    CoassDetailsMixin,
    StorageGetterMixin,
  ],
  data() {
    return {
      repository: "insurance_policy",
      isLoadingAjax: false,
      ajaxOptions: [],
      broker_options: [],
      mandate_code_options: [],
      companies: [],
      risk_branches: [],
      cooperators: [],
      risks: [],
      products: [],
      status_policy_opt: [
        { value: 0, text: "Non Perfezionata" },
        { value: 1, text: "Perfezionata" },
        { value: 2, text: "Sospesa" },
      ],
      isBreakdown: false,
      isInsurerParticipant: false,
      isLoadingBreakdown: false,
      registry_data: null,
      breakdowns: [],
      coassDetails: [],
      fields: [
        {
          key: "title",
          label: "Scorporo",
        },
        {
          key: "net",
          label: "Netto",
        },
        {
          key: "tax",
          label: "Tasse",
        },
        {
          key: "grossRow",
          label: "Lordo Riga",
        },
      ],
      id: this.$route.params.id,
      form: {
        inpt_label: "",
        quick_value: "",
        risk_id: null,
        risk_branch_id: null,
        insurer_participant: null,
        insurance_policy: {
          attribute_NONOT_value: "N",
          gross_premium: null,
          net_premium: 0.0,
          mandate_code_id: null,
          tax_premium: 0.0,
          number: null,
          broker_id: null,
          registry_id: null,
          insurer_id: null,
          insurance_risk_id: null,
          status_policy: null,
          title: null,
          covered_at: null,
          effective_at: null,
          expired_at: null,
          issued_at: today(),
          cancelled_at: null,
          payment_splitting: null,
          collaborator_id: null,
          collaboration_type: null,
          fee_net: 0.0,
          fee_tax: 0.0,
          fee_gross: 0.0,
          sharing: null,
          sharing_type: null,
        },
        breakdown: {},
        coass: {
          // EXTERNAL models
          // share: null, // Quota Nostra - > NO! usare form[rep].sharing
          // DYNAMICALLY ADD ELEMENTS
          coass_details: {},
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    CustomInputs,
    BaseDatepicker,
    BaseCurrency,
    QuickSearchRegistryModal,
    BaseLabel,
    CoassDetails,
  },
  methods: {
    toInfoData,
    onSubmit() {
      // checks...
      // delega nostra...
      // la sezione è attiva ed è delega nostra
      // CHECK this.form.insurer_participant here!!!
      // if (this.isInsurerParticipant && this.form.insurer_participant == 0) {
      if (this.isInsurerParticipant && this.form[this.rep].sharing_type == 0) {
        // 1. Nostra Quota + tutte le Quote deve fare 100
        if (
          parseFloat(
            // this.form.coass.share + this.coassDetailsCreateTotal("share")
            this.form[this.rep].sharing + this.coassDetailsCreateTotal("share")
          ).toFixed(2) !== "100.00"
        ) {
          this.$showSnackbar({
            preset: "error",
            text: "La quota totale deve essere 100%",
          });
          return;
        }
      }
      if (this.isBreakdown) {
        this.form[this.rep].net_premium = sumBreakdowns(this.breakdowns, "net");
        this.form[this.rep].gross_premium =
          sumBreakdowns(this.breakdowns, "net") +
          sumBreakdowns(this.breakdowns, "tax");
        this.form[this.rep].tax_premium = sumBreakdowns(this.breakdowns, "tax");
      } else {
        this.form[this.rep].gross_premium =
          this.form[this.rep].net_premium + this.form[this.rep].tax_premium;
      }
      this.isLoading = true;
      // const Repo = RepositoryFactory.get(this.repository);
      // Repo.store(this.form[this.repository])
      let insurance_policy_id;
      let insurance_ancillary;
      let promises = [];
      this.store(this.repository)
        .then((response) => {
          insurance_policy_id = response.data.data.id;
          insurance_ancillary = response.data.data.insurance_ancillaries.find(
            (e) => e.insurance_policy_id === insurance_policy_id
          );

          // // POST | PUT /api/insurance_ancillaries/ID-creato/pivot/insurer_participant
          // let payload = {
          //     "insurer_participant": {
          //         "1": {
          //             "share": 20,
          //             "net": 100,
          //             "tax": 10,
          //             "gross": 110,
          //             "participant_prov_purchase": 10,
          //             "participant_prov_take": 20
          //         }
          //     }
          // }

          if (
            this.isInsurerParticipant &&
            this.form[this.rep].sharing_type === 1
          ) {
            // delega altrui
            /* // 1. POST insurance_policies; sharing_type e sharing
              coasses.push(this.update(this.repository, insurance_policy_id)); */
            promises.push(
              this.addCoassPolicy(insurance_policy_id, "insurer_participant")
            );
          } else if (
            this.isInsurerParticipant &&
            this.form[this.rep].sharing_type === 0
          ) {
            // delega nostra
            // 1. POST insurance_ancillaries pivot on relation insurer_participant
            promises.push(
              this.addCoassAncillary(
                insurance_ancillary.id,
                "insurer_participant"
              )
            );
          }

          if (this.isBreakdown && insurance_ancillary) {
            for (let i = 0; i < this.breakdowns.length; i++) {
              let breakdownId = this.breakdowns[i].id;
              this.form.breakdown[breakdownId] = {
                net: this.breakdowns[i].net.value,
                tax: this.breakdowns[i].tax.value,
              };
            }
            promises.push(
              this.addBreakdownables(
                insurance_ancillary.title_id,
                "breakdown",
                {
                  breakdown: this.form.breakdown,
                }
              )
            );
            // let stores = this.addBreakdownables(
            //   insurance_ancillary.title_id,
            //   "breakdown",
            //   {
            //     breakdown: this.form.breakdown,
            //   }
            // );
            // }
            // Promise.all([promises])
            //   .then(() => {
            //     this.onSuccess(insurance_policy_id);
            //   })
            //   .catch((error) => {
            //     let errMsg = this.$getErrorMessage(error);
            //     this.$showSnackbar({
            //       preset: "error",
            //       text: `${errMsg}`,
            //     });
            //   })
            //   .finally(() => {
            //     this.isLoading = false;
            //   });
          } /* else {
            this.onSuccess(insurance_policy_id);
          } */

          Promise.all(promises)
            .then(() => {
              /* //status_policy "perfezionata"
              this.onSuccess(insurance_policy_id); */
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
          /* .finally(() => {
              this.isLoading = false;
            }); */

          // else {
          //   this.onSuccess(insurance_policy_id);
          // }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.onSuccess(insurance_policy_id);
        });
    },
    onSuccess(id) {
      this.showConfirm({
        yesCallback: () => {
          this.shortcut("module.PFOLIO", null, "#Policies", null, null, {
            click: "openModal",
            id: id,
            modalName: "modal",
            hash: "#General",
          });
        },
        noCallback: () => {
          this.shortcut("module.PFOLIO", null, "#Policies");
        },
        title: "Polizza creata",
        message: "Visualizzare il dettaglio?",
        yesLabel: "VISUALIZZA",
        noLabel: "ANNULLA",
      });
    },
    addBreakdownables(insuranceAncillaryId, relation, payload) {
      const Repo = RepositoryFactory.get("insurance_ancillary");
      return Repo.pivot_store(insuranceAncillaryId, relation, payload);
    },
    addCoassAncillary(insuranceAncillaryId, relation) {
      // POST /api/insurance_ancillaries/ID-creato/pivot/insurer_participant
      let payload = {
        insurer_participant: {},
      };
      // Coass Detail
      for (const e of Object.values(this.form.coass.coass_details)) {
        // NOTA: per un retaggio del componente EntryDetails, type corrisponde all'id,
        // che essendo univoco viene utilizzato per evitare di aggiungere più volte lo stesso dettaglio
        if (!e.type) {
          // se null, non ci sono dettagli valorizzati
          continue;
        }
        payload.insurer_participant[e.type] = {
          share: e.share || 0,
          net: e.net || 0,
          tax: e.tax || 0,
          gross: (e.net || 0) + (e.tax || 0),
          participant_prov_purchase: 0,
          participant_prov_take: 0,
        };
      }
      const Repo = RepositoryFactory.get("insurance_ancillary");
      return Repo.pivot_store(insuranceAncillaryId, relation, payload);
    },
    addCoassPolicy(insurancePolicyId, relation) {
      // POST /api/insurance_policies/ID-creato/pivot/insurer_participant
      let payload = {
        insurer_participant: {},
      };
      payload.insurer_participant[this.form.insurer_participant] = {
        share: this.form[this.rep].sharing,
      };
      const Repo = RepositoryFactory.get("insurance_policy");
      return Repo.pivot_store(insurancePolicyId, relation, payload);
    },
    onPaymentSplitting(val, skip = false) {
      // Data decorrenza = Data emissione
      if (!skip) {
        this.form[this.repository].effective_at =
          this.form[this.repository].issued_at;
      }
      // Data scadenza = Data decorrenza + 1 anno
      this.form[this.repository].expired_at = calcPolicyDate(
        this.form[this.repository].effective_at
      ).format("YYYY-MM-DD");
      // Data copertura = Data decorrenza + Frazionamento
      this.form[this.repository].covered_at = calcPolicyDate(
        this.form[this.repository].effective_at,
        val
      ).format("YYYY-MM-DD");
    },
    onInputInsurer(insurer) {
      this.form[this.rep].insurer_id = insurer;
      this.form.risk_branch_id = null;
      this.risk_branches = this.getBranches()(insurer);
      this.form[this.rep].insurance_risk_id = null;
      this.form[this.rep].delay_days = null;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        this.form.risk_branch_id,
        this.form.risk_id
      );
      this.form[this.rep].mandate_code_id = null;
      this.mandate_code_options = [];
      console.log(this.getMandateCodes()());
      if (insurer) {
        this.mandate_code_options = this.getMandateCodes()(insurer);
      }
    },
    onInputBranch(branches) {
      this.form[this.rep].insurance_risk_id = null;
      this.form[this.rep].delay_days = null;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        branches,
        this.form.risk_id
      );
    },
    openQuickSearchRegistry() {
      this.form[this.rep].registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.form[this.rep].registry_id = value.id;
      this.registry_data = value;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    onRemoveRisk() {
      if (this.isBreakdown) {
        this.clearPremiumVModel();
        this.breakdowns = [];
        this.isBreakdown = this.isBreakdown ? false : false;
      }
      this.form[this.rep].delay_days = null;
    },
    onSelectRisk(value) {
      // Set Delay Days
      const selectedProduct = this.getProducts()().find(
        (product) => product.value === value
      );
      this.form[this.rep].delay_days = selectedProduct
        ? selectedProduct.delay_days
        : null;
      // Breakdowns
      this.isLoadingBreakdown = true;
      const Repo = RepositoryFactory.get("breakdown");
      let querystring = `byInsuranceRisk[id]=${value}`;
      Repo.index(querystring)
        .then((response) => {
          const data = response.data.data;
          if (data.length) {
            this.breakdowns = dataMappingPremium(data);
          } else {
            this.isBreakdown = false;
            this.breakdowns = [];
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoadingBreakdown = false;
        });
    },
    onClickBreakdownsSwitch() {
      if (!this.form[this.rep].insurance_risk_id) {
        this.$showSnackbar({
          preset: "error",
          text: `Devi selezionare un prodotto per abilitare gli scorpori`,
        });
      }
    },
    onChangeBreakdownsSwitch() {
      this.clearPremiumVModel();
    },
    // onSelectInsurerParticipantOptions(val) {
    //   // reset del v-model
    //   this.form[this.rep].sharing = null;
    //   this.form.insurer_participant = null;
    // },
    // onRemoveInsurerParticipantOptions(val) {
    //   // reset del v-model
    //   this.form[this.rep].sharing = null;
    //   this.form.insurer_participant = null;
    // },
    onInputInsurerParticipantOptions() {
      // reset del v-model
      this.form[this.rep].sharing = null;
      this.form.insurer_participant = null;
    },
    onInputCooperator(value) {
      if (!value) {
        this.form[this.rep].collaboration_type = null;
      } else {
        const Repo = RepositoryFactory.get("broker");
        Repo.edit(value)
          .then((response) => {
            let v = response.data.fields.attribute_TKTP_value.value;
            this.form[this.rep].collaboration_type = v;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: errMsg,
            });
          });
      }
    },
    clearPremiumVModel() {
      if (!this.isBreakdown) {
        this.form[this.rep].net_premium = 0.0;
        this.form[this.rep].gross_premium = 0.0;
        this.form[this.rep].tax_premium = 0.0;
      }
      if (this.isBreakdown && this.isInsurerParticipant) {
        this.isInsurerParticipant = false;
      }
    },
    reduceSum(array, field) {
      let initialValue = 0;
      let sum = array.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue[field].value;
      }, initialValue);
      return sum;
    },
    grossRowBookEntry(net, tax) {
      return toLocaleCurrency(net + tax);
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getCooperators: "cooperators",
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
      getMandateCodes: "mandate_codes",
    }),
  },
  mounted() {
    // const companiesWithoutImporter = this.$store.state.auth.insurers
    //   .filter((e) => !e.importer_id)
    //   .map((e) => e.id);
    // this.companies = this.getInsurers().filter((e) =>
    //   companiesWithoutImporter.includes(e.value)
    // );
    // this.companies = this.getInsurers();
    this.risk_branches = this.getBranches()(this.form[this.rep].insurer_id);
    this.risks = this.getRisks();
    // this.cooperators = this.getCooperators();
  },
  created() {
    this.isLoading = true;
    this.broker_options = this.getSalesmen();
    this.fetchCreateForm(this.repository).then(() => {
      this.ajaxOptions = [];
      this.isLoading = false;
    });
    /* */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("cooperators")) {
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.cooperators = this.getCooperators();
    }
    if (!this.hasLoadedResource("insurers")) {
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.companies = this.getInsurers();
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.cooperators = this.getCooperators();
          this.companies = this.getInsurers();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
  computed: {
    netSum: {
      get() {
        return toLocaleCurrency(sumBreakdowns(this.breakdowns, "net"));
      },
    },
    taxSum: {
      get() {
        return toLocaleCurrency(sumBreakdowns(this.breakdowns, "tax"));
      },
    },
    grossSum: {
      get() {
        return toLocaleCurrency(
          sumBreakdowns(this.breakdowns, "net") +
            sumBreakdowns(this.breakdowns, "tax")
        );
      },
    },
    grossPremiumSum: {
      get() {
        return toLocaleCurrency(
          this.form[this.rep].net_premium + this.form[this.rep].tax_premium
        );
      },
    },
    grossSumFee() {
      return this.form[this.rep].fee_net + this.form[this.rep].fee_tax;
    },
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
    coassDetailsCreateTotal() {
      return (field) => {
        return Object.keys(this.form.coass.coass_details)
          .map((key) => {
            return +this.form.coass.coass_details[key][field] || 0;
          })
          .reduce(function (sum, i) {
            return sum + i;
          }, 0);
      };
    },
    rep() {
      return this.repository;
    },
    prdct() {
      return this.products;
    },
    breakdownable() {
      return "breakdownable";
    },
  },
  watch: {
    "form.inpt_label": {
      deep: true,
      handler() {
        if (!this.form[this.rep].registry_id) {
          this.registry_data = null;
        }
      },
    },
  },
};
</script>

<style scoped>
.registry_id {
  text-align: center;
}
.info {
  display: inline-block;
  margin: 10px 20px 15px 0;
  padding: 5px 10px;
}
p {
  margin-bottom: 0.5rem;
}
.switch {
  width: 200px;
}
</style>
